import {z} from 'zod'
import { ImageResource } from './ImageResource'
import { AmenityResource } from './AmenityResource'

export const NoteResource = z.object({
  id: z.number(),
  icon: z.string().nullable(),
  text: z.string(),
})

export type NoteResource = z.infer<typeof NoteResource>

export const LocationResource = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  address: z.string(),
  city: z.string(),
  country: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  price: z.number(),
  vat: z.number(),
  vat_name: z.string(),
  terms: z.string(),
  // day_capacity: z.number(),
  open_time: z.string().optional(),
  close_time: z.string().optional(),
  // evening_capacity: z.number(),
  // is_active: z.number(),
  // location_type_id: z.number(),
  // owner_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  // location_type: LocationTypeResource,
  // owner: LocationOwnerResource,
  is_active: z.number().optional(),
  rating: z.string().optional(),
  amenities: z.array(AmenityResource),
  images: z.array(ImageResource),
  notes: z.array(NoteResource),
  additional_info: z.array(NoteResource),
  is_office_manually_closed: z.number().default(0),
  is_meeting_manually_closed: z.number().default(0),
  qpark_link: z.string().nullable().optional(),
})

export type LocationResource = z.infer<typeof LocationResource>
